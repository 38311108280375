import {
  AdapterMoment,
  Box,
  Button,
  CachedIcon,
  CloseIcon,
  DatePicker,
  FormControl,
  LoadingButton,
  LocalizationProvider,
  MenuItem,
  SearchIcon,
  Select,
  TextField,
  Typography,
  styled,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  setCurrentPage,
  setFilter,
  setSearchValue,
  setSince,
  updateSelectedFustigations,
} from "../../store/slices/fustigations";
import { getFustigations } from "../../store/thunks/fustigations";
import { useState } from "react";
import MassiveFustigation from "../MassiveFustigation/MassiveFustigation";
import moment from "moment";
import { SEARCH_FILTERS } from "../../constants";
import { SearchFilters } from "../../models/fustigations";

const FustigationTableActions = () => {
  const selectedFustigations = useSelector(
    (state: RootState) => state.fustigations.selectedFustigations
  );
  const since = useSelector((state: RootState) => state.fustigations.since);
  const loading = useSelector((state: RootState) => state.fustigations.loading);
  const searchText = useSelector((state: RootState) => state.fustigations.searchValue);
  const searchFilter = useSelector((state: RootState) => state.fustigations.filter);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const handleSearch = () => {
    if (!since) return;
    dispatch(setCurrentPage(1));
    dispatch(getFustigations({ since }));
    resetSelectedFrts();
  };

  const resetSelectedFrts = () => {
    dispatch(updateSelectedFustigations([]));
  };

  const resetSearch = () => {
    dispatch(setSince(moment().subtract(1, "day").startOf("day").format("YYYY-MM-DDTHH:mm:ssZZ")));
    console.log("since: ", since);
    dispatch(setSearchValue(""));
    dispatch(setFilter(SearchFilters.METER_SERIAL));
    dispatch(
      getFustigations({
        since: moment().subtract(1, "day").startOf("day").format("YYYY-MM-DDTHH:mm:ssZZ"),
      })
    );
  };

  return (
    <>
      <Typography sx={{ color: "#3D4350", fontSize: "16px", fontWeight: 700 }}>
        Filtrar por
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box sx={{ display: "flex", width: "450px" }}>
            <Select
              value={searchFilter}
              disabled={loading}
              onChange={(event) => dispatch(setFilter(event.target.value as SearchFilters))}
              sx={{
                "& fieldset": {
                  borderRadius: "12px 0px 0px 12px !important",
                },
              }}>
              {SEARCH_FILTERS.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              fullWidth
              placeholder='Buscar'
              disabled={loading}
              value={searchText}
              onChange={(event) => dispatch(setSearchValue(event.target.value))}
              sx={{ borderRadius: "0px 12px 12px 0px !important" }}
              InputProps={{
                sx: {
                  "& fieldset": {
                    borderRadius: "0px 12px 12px 0px !important",
                  },
                },
              }}
            />
          </Box>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format='YYYY-MM-DD'
                value={moment(since)}
                // maxDate={moment.now()}
                disabled={loading}
                onChange={(value: any) => {
                  console.log("value: ", value);
                  dispatch(setSince(value.format("YYYY-MM-DDTHH:mm:ssZZ")));
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: false,
                    sx: {
                      "& fieldset": {
                        borderRadius: "12px",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <LoadingButton
            variant='contained'
            onClick={handleSearch}
            sx={{ minHeight: "unset !important", height: "50px" }}>
            <SearchIcon />
          </LoadingButton>
          <LoadingButton
            variant='contained'
            onClick={resetSearch}
            sx={{ minHeight: "unset !important", height: "50px" }}>
            <CachedIcon />
          </LoadingButton>
        </Box>
        <Button
          sx={{ height: "51px", minHeight: "unset !important" }}
          onClick={() => setOpenModal(true)}
          color='primary'
          variant='contained'>
          Refustigación masiva
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2,
          gap: 5,
        }}>
        <StatusIndicator bgColor='#EFE9FB' fontColor='#53358E'>
          <Box sx={{ display: "flex" }}>
            <Typography>Seleccionados: </Typography>
            <Typography sx={{ ml: 1, fontWeight: 700 }}>{selectedFustigations.length}</Typography>
          </Box>
          <Button
            onClick={resetSelectedFrts}
            sx={{
              minWidth: "unset !important",
              width: "24px",
              height: "24px",
              borderRadius: "50% !important",
            }}>
            <CloseIcon sx={{ fontSize: "21px" }} />
          </Button>
        </StatusIndicator>
      </Box>
      <MassiveFustigation open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

export default FustigationTableActions;

interface StatusColor {
  fontColor: string;
  bgColor: string;
}

const StatusIndicator = styled("div")<StatusColor>(({ fontColor, bgColor }) => ({
  width: "180px",
  height: "32px",
  backgroundColor: bgColor,
  color: fontColor,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  borderRadius: "8px",
}));
